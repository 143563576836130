<template>
    <div>
        <transition name="fade">
            <div class="new-password">
                <transition name="fade" mode="out-in">
                    <FormulateForm v-if="!changePassword" class="new-password-form">
                        <div class="grid-dashboard">
                            <FormulateErrors/>

                            <div class="new-password-form-block grid-dashboard">
                                <div class="grid-item">
                                    <FormulateInput
                                        name="password"
                                        :type="toogleType"
                                        label="Новый пароль"
                                        class="big"
                                        v-changeFocusBlur
                                        :watchView="toogleType"
                                        autocomplete="off"
                                    />
                                </div>
                                <div class="grid-item">
                                    <FormulateInput
                                        name="password"
                                        :type="toogleType"
                                        label="Подтвердите новый пароль"
                                        class="big"
                                        v-changeFocusBlur
                                        :watchView="toogleType"
                                        autocomplete="off"
                                    />
                                </div>
                                <div class="grid-item">
                                    <button class="btn-big btn-text primary"
                                            @click="openNotification"
                                    >
                                        Сменить пароль
                                    </button>
                                </div>
                            </div>
                        </div>
                    </FormulateForm> 


                    <div v-else class="new-password-success"> 
                        <p>Ваш пароль успешно изменен. Вы можете использовать новый пароль для входа в систему.</p>
                        <router-link
                            class="btn-big btn-text primary"
                            to="/"
                            tag="button"
                        >
                            Вернуться на главную
                        </router-link>
                    </div>
                </transition>
                    
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'new-password',
    data() {
        return {
            changePassword: false,
            toogleType: 'password',
        }
    },
    methods: {
        openNotification() {
            this.changePassword = !this.changePassword;
        }
    }
}
</script>

<style>

</style>